import moment from "moment";
import {
  DEFAULT_BOOKING_COLUMNS,
  GET_BOOKINGS,
  GET_BOOKINGS_CSV,
} from "../graphql/booking.query";
import {
  DEFAULT_PAYMENT_COLUMNS,
  GET_PAYMENTS,
  GET_PAYMENTS_CSV,
} from "../graphql/payment.query";
import {
  DEFAULT_REFUND_COLUMNS,
  GET_REFUNDS,
  GET_REFUNDS_CSV,
} from "../graphql/refund.query";
import { steps } from "./misc.utils";

export const BookingColumns = [
  { name: "PNR", value: "PNR", selector: (row: any) => row.PNR },
  {
    name: "Journey Date",
    value: "journeyDate",
    selector: (row: any) => row.journeyDate,
  },
  {
    name: "Source Station",
    value: "source",
    selector: (row: any) => row.source,
  },
  {
    name: "Destination Station",
    value: "destination",
    selector: (row: any) => row.destination,
  },
  {
    name: "Booking Date",
    value: "bookingDate",
    selector: (row: any) => row.bookingDate,
  },
  {
    name: "User ID",
    value: "userId",
    selector: (row: any) => row.userId,
  },
  {
    name: "Order ID",
    value: "clientTransactionId",
    selector: (row: any) => row.clientTransactionId,
  },
  {
    name: "Reservation ID",
    value: "reservationId",
    selector: (row: any) => row.reservationId,
  },
  {
    name: "Payment mode",
    value: "payment_mode",
    selector: (row: any) => row.payment_mode,
  },
  {
    name: "Card Scheme",
    value: "card_scheme",
    selector: (row: any) => row.card_scheme,
  },
  {
    name: "AskDisha Mobile No.",
    value: "mobileNumber",
    selector: (row: any) => row.mobileNumber,
  },
  {
    name: "BookingData",
    value: "bookingData",
    selector: (row: any) => row.bookingData,
  },
  {
    name: "Catering Charge",
    value: "cateringcharge",
    selector: (row: any) => row.cateringcharge,
  },
  {
    name: "Distance",
    value: "distance",
    selector: (row: any) => row.distance,
  },
  {
    name: "Fuel Amount",
    value: "fuelamount",
    selector: (row: any) => row.fuelamount,
  },
  {
    name: "OTP Authentication Flag",
    value: "otpauthenticationflag",
    selector: (row: any) => row.otpauthenticationflag,
  },
  {
    name: "Reservation Charge",
    value: "reservationcharge",
    selector: (row: any) => row.reservationcharge,
  },
  {
    name: "Server ID",
    value: "serverid",
    selector: (row: any) => row.serverid,
  },
  {
    name: "Service Tax",
    value: "servicetax",
    selector: (row: any) => row.servicetax,
  },
  {
    name: "Super Fast Charge",
    value: "superfastcharge",
    selector: (row: any) => row.superfastcharge,
  },
  {
    name: "Tatkal Fare",
    value: "tatkalfare",
    selector: (row: any) => row.tatkalfare,
  },
  {
    name: "Timestamp",
    value: "timeStamp",
    selector: (row: any) => row.timeStamp,
  },
  {
    name: "Total Fare",
    value: "totalfare",
    selector: (row: any) => row.totalfare,
  },
  {
    name: "Train Name",
    value: "trainname",
    selector: (row: any) => row.trainname,
  },
  {
    name: "Train Owner",
    value: "trainowner",
    selector: (row: any) => row.trainowner,
  },
  {
    name: "WP Service Charge",
    value: "wpservicecharge",
    selector: (row: any) => row.wpservicecharge,
  },
  {
    name: "WP Service Tax",
    value: "wpservicetax",
    selector: (row: any) => row.wpservicetax,
  },
  {
    name: "Arrival Time",
    value: "arrivaltime",
    selector: (row: any) => row.arrivaltime,
  },
  {
    name: "Boarding Station Name",
    value: "boardingstnname",
    selector: (row: any) => row.boardingstnname,
  },
  {
    name: "Booked Quota",
    value: "bookedquota",
    selector: (row: any) => row.bookedquota,
  },
  {
    name: "Boarding Station",
    value: "boardingstn",
    selector: (row: any) => row.boardingstn,
  },
  {
    name: "Can Spouse Flag",
    value: "canspouseflag",
    selector: (row: any) => row.canspouseflag,
  },
  {
    name: "Information Message",
    value: "informationmessage",
    selector: (row: any) => row.informationmessage,
  },
  {
    name: "Departure Time",
    value: "departuretime",
    selector: (row: any) => row.departuretime,
  },
  {
    name: "Destination Arrival Date",
    value: "destinationarrivaldate",
    selector: (row: any) => row.destinationarrivaldate,
  },
  {
    name: "Complaint Flag",
    value: "complaintflag",
    selector: (row: any) => row.complaintflag,
  },
  {
    name: "From Station Name",
    value: "fromstnname",
    selector: (row: any) => row.fromstnname,
  },
  {
    name: "GST Charge",
    value: "gstcharge",
    selector: (row: any) => row.gstcharge,
  },
  {
    name: "GST Flag",
    value: "gstflag",
    selector: (row: any) => row.gstflag,
  },
  {
    name: "Insurance Dopted",
    value: "insurancedopted",
    selector: (row: any) => row.insurancedopted,
  },
  {
    name: "Journey Class",
    value: "journeyclass",
    selector: (row: any) => row.journeyclass,
  },
  {
    name: "Journey Lap",
    value: "journeylap",
    selector: (row: any) => row.journeylap,
  },
  {
    name: "Journey Quota",
    value: "journeyquota",
    selector: (row: any) => row.journeyquota,
  },
  {
    name: "Lap Number",
    value: "lapnumber",
    selector: (row: any) => row.lapnumber,
  },
  {
    name: "Mahakal Flag",
    value: "mahakalflag",
    selector: (row: any) => row.mahakalflag,
  },
  {
    name: "Meal Choice Enable",
    value: "mealchoiceenable",
    selector: (row: any) => row.mealchoiceenable,
  },
  {
    name: "Meal Transaction",
    value: "mealtransaction",
    selector: (row: any) => row.mealtransaction,
  },
  {
    name: "ML Journey Type",
    value: "mljourneytype",
    selector: (row: any) => row.mljourneytype,
  },
  {
    name: "ML Reservation Status",
    value: "mlreservationstatus",
    selector: (row: any) => row.mlreservationstatus,
  },
  {
    name: "ML Time Diff",
    value: "mltimediff",
    selector: (row: any) => row.mltimediff,
  },
  {
    name: "ML Transaction Status",
    value: "mltransactionstatus",
    selector: (row: any) => row.mltransactionstatus,
  },
  {
    name: "ML User ID",
    value: "mluserid",
    selector: (row: any) => row.mluserid,
  },
  {
    name: "Month Booking Ticket",
    value: "monthbkgticket",
    selector: (row: any) => row.monthbkgticket,
  },
  {
    name: "Multi Lap Flag",
    value: "multilapflag",
    selector: (row: any) => row.multilapflag,
  },
  {
    name: "Number of Adult",
    value: "numberofadult",
    selector: (row: any) => row.numberofadult,
  },
  {
    name: "Number of Childs",
    value: "numberofchilds",
    selector: (row: any) => row.numberofchilds,
  },
  {
    name: "Number of Passenger",
    value: "numberofpassenger",
    selector: (row: any) => row.numberofpassenger,
  },
  {
    name: "Policy Status",
    value: "policystatus",
    selector: (row: any) => row.policystatus,
  },
  {
    name: "Passenger Detail List",
    value: "passengerdetaillist",
    selector: (row: any) => row.passengerdetaillist,
  },
  {
    name: "Retry Booking",
    value: "retrybooking",
    selector: (row: any) => row.retrybooking,
  },
  {
    name: "Reason Index",
    value: "reasonindex",
    selector: (row: any) => row.reasonindex,
  },
  {
    name: "Reason Type",
    value: "reasontype",
    selector: (row: any) => row.reasontype,
  },
  {
    name: "Requested Client Transactionid",
    value: "requestedclienttransactionid",
    selector: (row: any) => row.requestedclienttransactionid,
  },
  {
    name: "Insured Passenger Count",
    value: "insuredpassengercount",
    selector: (row: any) => row.insuredpassengercount,
  },
  {
    name: "Insurance Charge",
    value: "insurancecharge",
    selector: (row: any) => row.insurancecharge,
  },
  {
    name: "Insurance Company",
    value: "insurancecompany",
    selector: (row: any) => row.insurancecompany,
  },
  {
    name: "Insurance Company URL",
    value: "insurancecompanyurl",
    selector: (row: any) => row.insurancecompanyurl,
  },
  {
    name: "Reservation Upto Code",
    value: "reservationupto",
    selector: (row: any) => row.reservationupto,
  },
  {
    name: "Reservation Upto Name",
    value: "reservationuptoname",
    selector: (row: any) => row.reservationuptoname,
  },
  {
    name: "RRHB Flag",
    value: "rrhbflag",
    selector: (row: any) => row.rrhbflag,
  },
  { name: "SAI", value: "sai", selector: (row: any) => row.sai },
  {
    name: "Schedule Arrival Flag",
    value: "schedulearrivalflag",
    selector: (row: any) => row.schedulearrivalflag,
  },
  {
    name: "Schedule Departure Flag",
    value: "scheduledepartureflag",
    selector: (row: any) => row.scheduledepartureflag,
  },
  {
    name: "Sector ID",
    value: "sectorid",
    selector: (row: any) => row.sectorid,
  },
  {
    name: "Service Charge total",
    value: "servicechargetotal",
    selector: (row: any) => row.servicechargetotal,
  },
  {
    name: "Ticket Type",
    value: "tickettype",
    selector: (row: any) => row.tickettype,
  },
  {
    name: "Time Difference",
    value: "timediff",
    selector: (row: any) => row.timediff,
  },
  {
    name: "Time Table Flag",
    value: "timetableflag",
    selector: (row: any) => row.timetableflag,
  },
  {
    name: "Total Collectable Amount",
    value: "totalcollectableamount",
    selector: (row: any) => row.totalcollectableamount,
  },
  {
    name: "Total Refund Amount",
    value: "totalrefundamount",
    selector: (row: any) => row.totalrefundamount,
  },
  {
    name: "Tourism URL",
    value: "tourismurl",
    selector: (row: any) => row.tourismurl,
  },
  {
    name: "Train Number",
    value: "trainnumber",
    selector: (row: any) => row.trainnumber,
  },
  {
    name: "Travel Insurance Refund Amount",
    value: "travelinsurancerefundamount",
    selector: (row: any) => row.travelinsurancerefundamount,
  },
  {
    name: "Vikalp Status",
    value: "vikalpstatus",
    selector: (row: any) => row.vikalpstatus,
  },
  {
    name: "Email",
    value: "email",
    selector: (row: any) => row.email,
  },
  {
    name: "UUID",
    value: "UUID",
    selector: (row: any) => row.UUID,
  },
  { name: "dtm", value: "dtm", selector: (row: any) => row.dtm },
];

export const BookingSearch = [
  {
    name: "Order ID",
    value: "clientTransactionId",
    selector: (row: any) => row.clientTransactionId,
  },
  { name: "PNR", value: "PNR", selector: (row: any) => row.PNR },
  {
    name: "Reservation ID",
    value: "reservationId",
    selector: (row: any) => row.reservationId,
  },
  {
    name: "User ID",
    value: "userId",
    selector: (row: any) => row.userId,
  },
  {
    name: "Mobile Number",
    value: "mobileNumber",
    selector: (row: any) => row.mobileNumber,
  },
];

export const RefundColumns = [
  {
    name: "PNR",
    value: "PNR",
    selector: (row: any) => row.PNR,
  },
  {
    name: "Reference ID",
    value: "reference_id",
    selector: (row: any) => row.reference_id,
  },
  { name: "RRN", value: "rrn", selector: (row: any) => row.rrn },
  {
    name: "Accept Refund Status",
    value: "accept_refund_status",
    selector: (row: any) => row.accept_refund_status,
  },
  {
    name: "Accept Refund Timestamp",
    value: "accept_refund_timestamp",
    selector: (row: any) => row.accept_refund_timestamp,
  },
  {
    name: "Card Scheme",
    value: "card_scheme",
    selector: (row: any) => row.card_scheme,
  },
  {
    name: "Employee email",
    value: "emp_email",
    selector: (row: any) => row.emp_email,
  },
  {
    name: "Employee ID",
    value: "employee_id",
    selector: (row: any) => row.employee_id,
  },
  {
    name: "IFSC Code",
    value: "ifsc_code",
    selector: (row: any) => row.ifsc_code,
  },
  {
    name: "Issuing Bank Code",
    value: "issuing_bank_code",
    selector: (row: any) => row.issuing_bank_code,
  },
  {
    name: "Issuing Bank Name",
    value: "issuing_bank_name",
    selector: (row: any) => row.issuing_bank_name,
  },
  {
    name: "Masked Bank Account Number",
    value: "masked_bank_account_number",
    selector: (row: any) => row.masked_bank_account_number,
  },
  {
    name: "Masked Card Number",
    value: "masked_card_number",
    selector: (row: any) => row.masked_card_number,
  },
  {
    name: "Masked VPA",
    value: "masked_vpa",
    selector: (row: any) => row.masked_vpa,
  },
  {
    name: "Max Refund Retry Timestamp",
    value: "max_refund_retry_timestamp",
    selector: (row: any) => row.max_refund_retry_timestamp,
  },
  {
    name: "Merchant Refund Request Timestamp",
    value: "merchant_refund_request_timestamp",
    selector: (row: any) => row.merchant_refund_request_timestamp,
  },
  {
    name: "Merchant ID",
    value: "merchant_id",
    selector: (row: any) => row.merchant_id,
  },
  {
    name: "Employee Name",
    value: "emp_name",
    selector: (row: any) => row.emp_name,
  },
  {
    name: "Order ID",
    value: "order_id",
    selector: (row: any) => row.order_id,
  },
  {
    name: "Payment Method",
    value: "pay_method",
    selector: (row: any) => row.pay_method,
  },
  {
    name: "Employee Phone",
    value: "emp_phone",
    selector: (row: any) => row.emp_phone,
  },
  {
    name: "Refund Amount",
    value: "refund_amount",
    selector: (row: any) => row.refund_amount,
  },
  {
    name: "Refund ID",
    value: "refund_id",
    selector: (row: any) => row.refund_id,
  },
  {
    name: "Refund Reason",
    value: "refund_reason",
    selector: (row: any) => row.refund_reason,
  },
  {
    name: "Refund Type",
    value: "refund_type",
    selector: (row: any) => row.refund_type,
  },
  {
    name: "Result Code",
    value: "result_code",
    selector: (row: any) => row.result_code,
  },
  {
    name: "Result Msg",
    value: "result_msg",
    selector: (row: any) => row.result_msg,
  },
  {
    name: "Result Status",
    value: "result_status",
    selector: (row: any) => row.result_status,
  },
  {
    name: "Source",
    value: "source",
    selector: (row: any) => row.source,
  },
  {
    name: "Total Refund Amount",
    value: "total_refund_amount",
    selector: (row: any) => row.total_refund_amount,
  },
  {
    name: "Transaction Amount",
    value: "txn_amount",
    selector: (row: any) => row.txn_amount,
  },
  {
    name: "Transaction ID",
    value: "txn_id",
    selector: (row: any) => row.txn_id,
  },
  {
    name: "Transaction Timestamp",
    value: "txn_timestamp",
    selector: (row: any) => row.txn_timestamp,
  },
  {
    name: "User Credit Expected Date",
    value: "user_credit_expected_date",
    selector: (row: any) => row.user_credit_expected_date,
  },
  {
    name: "User Credit Initiate Status",
    value: "user_credit_initiate_status",
    selector: (row: any) => row.user_credit_initiate_status,
  },
  {
    name: "User Credit Initiate Timestamp",
    value: "user_credit_initiate_timestamp",
    selector: (row: any) => row.user_credit_initiate_timestamp,
  },
  {
    name: "User Mobile Number",
    value: "user_mobile_no",
    selector: (row: any) => row.user_mobile_no,
  },
  {
    name: "Count",
    value: "count",
    selector: (row: any) => row.count,
  },
];

export const RefundSearch = [
  {
    name: "Order ID",
    value: "order_id",
    selector: (row: any) => row.order_id,
  },
  {
    name: "User Mobile Number",
    value: "user_mobile_no",
    selector: (row: any) => row.user_mobile_no,
  },
  {
    name: "Reference ID",
    value: "reference_id",
    selector: (row: any) => row.reference_id,
  },
  { name: "RRN", value: "rrn", selector: (row: any) => row.rrn },
];

export const PaymentColumns = [
  {
    name: "Auth Code",
    value: "auth_code",
    selector: (row: any) => row.auth_code,
  },
  {
    name: "Bank Name",
    value: "bank_name",
    selector: (row: any) => row.bank_name,
  },
  {
    name: "Bank Transaction ID",
    value: "bank_txn_id",
    selector: (row: any) => row.bank_txn_id,
  },
  {
    name: "Card Scheme",
    value: "card_scheme",
    selector: (row: any) => row.card_scheme,
  },
  {
    name: "Email ID",
    value: "email",
    selector: (row: any) => row.email,
  },
  {
    name: "Gateway Name",
    value: "gateway_name",
    selector: (row: any) => row.gateway_name,
  },
  {
    name: "Gateway Stage",
    value: "gateway_stage",
    selector: (row: any) => row.gateway_stage,
  },
  {
    name: "Mobile Number",
    value: "mobile",
    selector: (row: any) => row.mobile,
  },
  {
    name: "Order ID",
    value: "order_id",
    selector: (row: any) => row.order_id,
  },
  {
    name: "Payment Mode",
    value: "payment_mode",
    selector: (row: any) => row.payment_mode,
  },
  {
    name: "Refund Amount",
    value: "refund_amt",
    selector: (row: any) => row.refund_amt,
  },
  {
    name: "Response Timestamp",
    value: "response_timestamp",
    selector: (row: any) => row.response_timestamp,
  },
  {
    name: "Result Code",
    value: "result_code",
    selector: (row: any) => row.result_code,
  },
  {
    name: "Result Message",
    value: "result_msg",
    selector: (row: any) => row.result_msg,
  },
  {
    name: "Result Status",
    value: "result_status",
    selector: (row: any) => row.result_status,
  },
  {
    name: "RRN Code",
    value: "rrn_code",
    selector: (row: any) => row.rrn_code,
  },
  {
    name: "Transaction Amount",
    value: "txn_amount",
    selector: (row: any) => row.txn_amount,
  },
  {
    name: "Transaction Date",
    value: "txn_date",
    selector: (row: any) => row.txn_date,
  },
  {
    name: "Transaction Id",
    value: "txn_id",
    selector: (row: any) => row.txn_id,
  },
  {
    name: "Transaction Type",
    value: "txn_type",
    selector: (row: any) => row.txn_type,
  },
  {
    name: "User ID",
    value: "userid",
    selector: (row: any) => row.userid,
  },
];

export const PaymentSearch = [
  {
    name: "Bank Transaction ID",
    value: "bank_txn_id",
    selector: (row: any) => row.bank_txn_id,
  },
  {
    name: "Email ID",
    value: "email",
    selector: (row: any) => row.email,
  },
  {
    name: "Mobile Number",
    value: "mobile",
    selector: (row: any) => row.mobile,
  },
  {
    name: "Order ID",
    value: "order_id",
    selector: (row: any) => row.order_id,
  },
  {
    name: "RRN Code",
    value: "rrn_code",
    selector: (row: any) => row.rrn_code,
  },
  {
    name: "Transaction ID",
    value: "txn_id",
    selector: (row: any) => row.txn_id,
  },
  {
    name: "User ID",
    value: "userid",
    selector: (row: any) => row.userid,
  },
];

export const OverviewColumns = [
  {
    name: "Date",
    value: "date",
    selector: (row: any) => row.date,
  },
  {
    name: "Total Transactions",
    value: "total_payments",
    selector: (row: any) => row.total_payments,
  },
  {
    name: "Awaiting Settlement",
    value: "in_progress",
    selector: (row: any) => row.in_progress,
  },
  {
    name: "Successful Bookings",
    value: "success_bookings",
    selector: (row: any) => row.success_bookings,
  },
  {
    name: "UPI Pay by Voice",
    value: "voice_bookings",
    selector: (row: any) => row.voice_bookings,
  },
  {
    name: "Failed Bookings",
    value: "failed_bookings",
    selector: (row: any) => row.failed_bookings,
  },
  {
    name: "CRIS API Errors",
    value: "api_errors",
    selector: (row: any) => row.api_errors,
  },
  {
    name: "AskDISHA Errors",
    value: "others",
    selector: (row: any) => row.others,
  },
];

export const IntervalColumns = [
  {
    name: "Minute Interval",
    value: "time",
    selector: (row: any) => row.time,
  },
  {
    name: "Bookings",
    value: "count",
    selector: (row: any) => row.count,
  },
  {
    name: "Cumulative Booking Counts",
    value: "commulative",
    selector: (row: any) => row.commulative,
  },
];

export const ErrorColumns = [
  {
    name: "Error Message Received",
    value: "errorType",
    selector: (row: any) => row.errorType,
  },
  {
    name: "Count",
    value: "count",
    selector: (row: any) => row.count,
  },
];

export const FootfallColumns = [
  {
    name: "Step",
    value: "step",
    selector: (row: any) => row.step,
  },
  {
    name: "Count",
    value: "count",
    selector: (row: any) => row.count,
  },
];

export const getColumnList = (tableName: TableName) => {
  switch (tableName) {
    case "BOOKING": {
      return BookingColumns.filter(
        (elem) => !DEFAULT_BOOKING_COLUMNS.includes(elem.value)
      );
    }
    case "REFUND": {
      return RefundColumns.filter(
        (elem) => !DEFAULT_REFUND_COLUMNS.includes(elem.value)
      );
    }
    case "PAYMENT": {
      return PaymentColumns.filter(
        (elem) => !DEFAULT_PAYMENT_COLUMNS.includes(elem.value)
      );
    }
  }
};

export const getSearchColumns = (tableName: TableName) => {
  switch (tableName) {
    case "BOOKING": {
      return BookingSearch;
    }
    case "REFUND": {
      return RefundSearch;
    }
    case "PAYMENT": {
      return PaymentSearch;
    }
  }
};

export const getDefaultColumns = (tableName: TableName) => {
  switch (tableName) {
    case "BOOKING": {
      return DEFAULT_BOOKING_COLUMNS;
    }
    case "REFUND": {
      return DEFAULT_REFUND_COLUMNS;
    }
    case "PAYMENT": {
      return DEFAULT_PAYMENT_COLUMNS;
    }
  }
};

export const getAllColumns = (tableName: TableName) => {
  switch (tableName) {
    case "BOOKING": {
      return BookingColumns;
    }
    case "REFUND": {
      return RefundColumns;
    }
    case "PAYMENT": {
      return PaymentColumns;
    }
  }
};

export const CSV_QUERY = {
  BOOKING: GET_BOOKINGS_CSV,
  REFUND: GET_REFUNDS_CSV,
  PAYMENT: GET_PAYMENTS_CSV,
};

export enum TableName {
  BOOKING = "BOOKING",
  REFUND = "REFUND",
  PAYMENT = "PAYMENT",
}

export const DataKey = {
  BOOKING: "irctc_Booking",
  REFUND: "irctc_refund_status",
  PAYMENT: "irctc_payment_status",
};

export const AggregateKey = {
  BOOKING: "irctc_Booking_aggregate",
  REFUND: "irctc_refund_status_aggregate",
  PAYMENT: "irctc_payment_status_aggregate",
};

export const QueryFunction = {
  BOOKING: GET_BOOKINGS,
  REFUND: GET_REFUNDS,
  PAYMENT: GET_PAYMENTS,
};

export const bifurcatePayments = (
  payments: Array<any>,
  voicePayments: Array<any>,
  date: string
) => {
  const accumulator: any = {
    success_bookings: 0,
    failed_bookings: 0,
    api_errors: 0,
    others: 0,
  };
  let in_progress = 0;

  // const ids: any = [];
  let voice_bookings = 0;

  const grouped = payments.reduce((acc, elem) => {
    if (elem.gateway_stage === "1") ++in_progress;
    if (elem.gateway_stage === "2") {
      acc["success_bookings"] = (acc["success_bookings"] | 0) + 1;
      voice_bookings =
        voice_bookings +
        (voicePayments.find((e) => e.txnId === elem.order_id) ? 1 : 0);
    }
    if (elem.gateway_stage === "3") {
      acc["failed_bookings"] = (acc["failed_bookings"] | 0) + 1;
    }
    if (
      elem.error &&
      elem.error.data &&
      elem.gateway_stage === "3" &&
      elem.error.url.includes("/doBooking")
    ) {
      // ids.push(elem.order_id);
      acc["api_errors"] += 1;
    }
    return acc;
  }, accumulator);

  // console.log(
  //   payments.filter(
  //     (elem) => elem.gateway_stage === "3" && !ids.includes(elem.order_id)
  //   )
  // );

  const others = grouped.failed_bookings - grouped.api_errors;
  return {
    date: moment(date, "YYYY-MM-DD").format("DD-MM-YYYY"),
    total_payments: payments.length,
    in_progress,
    success_bookings: `${grouped.success_bookings} (${(
      (grouped.success_bookings * 100) /
      payments.length
    ).toFixed(2)}%)`,
    voice_bookings: `${voice_bookings} (${(
      (voice_bookings * 100) /
      grouped.success_bookings
    ).toFixed(2)}%)`,
    failed_bookings: `${grouped.failed_bookings} (${(
      (grouped.failed_bookings * 100) /
      payments.length
    ).toFixed(2)}%)`,
    api_errors: `${grouped.api_errors} (${(
      (grouped.api_errors * 100) /
      payments.length
    ).toFixed(2)}%)`,
    others: `${others} (${((others * 100) / payments.length).toFixed(2)}%)`,
  };
};

export const bifurcateTQData = (
  bookings: Array<{ dtm: string; journeyDate: string; bookedquota: string }>,
  hour: string,
  date: string
) => {
  const shouldHide =
    moment(date, "YYYY-MM-DD HH:mm:ss.0").diff(moment(Date.now()), "day") === 0;

  const dates: any = {};

  for (let i = 0; i < 15; i++) {
    const minute = i.toString().length === 1 ? `0${i}` : i;
    if (
      shouldHide &&
      moment(`${hour}:${minute} AM`, "HH:mm A").isAfter(moment(Date.now()))
    ) {
      dates[`${hour}:${minute} AM`] = "Will reflect soon";
    } else dates[`${hour}:${minute} AM`] = 0;
  }

  let count = 0;

  const grouped = bookings.reduce((acc, elem) => {
    const time = moment(elem.dtm, "MM/DD/YYYY HH:mm:ss").format("hh:mm A");
    if (time in acc && acc[time] !== "Will reflect soon") {
      const diff = Math.abs(
        moment(parseInt(elem.journeyDate))
          .add(+5.5, "hours")
          .diff(moment(date, "YYYY-MM-DD HH:mm:ss.0"), "days")
      );

      if (
        (hour === "08" && diff >= 119 && diff <= 120) ||
        (hour !== "08" && elem.bookedquota === "TATKAL")
      ) {
        acc[time] += 1;
        ++count;
      }
    }

    return acc;
  }, dates);

  const finalData = Object.keys(grouped).reduce(
    (acc, elem, index) => {
      acc.push({
        time: elem,
        count: grouped[elem],
        commulative:
          grouped[elem] !== "Will reflect soon"
            ? grouped[elem] + (index > 0 ? acc[index - 1].commulative : 0)
            : "Will reflect soon",
      });

      return acc;
    },
    [] as Array<{ time: string; count: number; commulative: number }>
  );

  finalData.push({
    time: "TOTAL",
    count,
    commulative: count,
  });
  return finalData;
};

export const bifurcateHourlyData = (
  bookings: Array<{ dtm: string }>,
  date: string
) => {
  const accumulator: any = {};

  for (let i = 0; i < 24; i++) {
    const hour = moment(`${i}:00`, "H:mm").format("HH:mm");
    if (
      moment(date).format("YYYY-MM-DD") !==
        moment(Date.now()).format("YYYY-MM-DD") ||
      moment(`${i}:59`, "HH:mm").diff(moment(Date.now())) < 0
    ) {
      accumulator[hour] = 0;
    } else accumulator[hour] = "Will reflect soon";
  }

  const grouped = bookings.reduce((acc, elem) => {
    const time = moment(elem.dtm, "MM/DD/YYYY HH:mm:ss").format("HH:00");
    if (time in acc && acc[time] !== "Will reflect soon") {
      acc[time] += 1;
    }
    return acc;
  }, accumulator);

  const finalData = Object.keys(grouped).reduce(
    (acc, elem, index) => {
      acc.push({
        time: `${elem} - ${moment(elem, "HH:mm")
          .add(+1, "hour")
          .format("HH:mm")}`,
        count: grouped[elem],
        commulative:
          grouped[elem] !== "Will reflect soon"
            ? grouped[elem] + (index ? acc[index - 1].commulative : 0)
            : "Will reflect soon",
      });

      return acc;
    },
    [] as Array<{ time: string; count: number; commulative: number }>
  );

  if (
    finalData[finalData.length - 1].count.toString() !== "Will reflect soon"
  ) {
    finalData.push({
      time: "TOTAL",
      count: bookings.length,
      commulative: bookings.length,
    });
  }

  return finalData;
};

export const bifurcateFootfall = (data: Array<{ data: string }>) => {
  const reduced = data.reduce((acc, elem) => {
    if (elem.data !== "") {
      const parsed = JSON.parse(elem.data);
      Object.keys(parsed).forEach((key) => {
        acc[key] = (acc[key] | 0) + parsed[key];
      });
    }

    return acc;
  }, {} as any);

  return reduced;
};

export const getFunnelData = (entries: Array<any>) => {
  return [
    {
      type: "funnel",
      name: "From Bot",
      x: entries.map((elem) => elem[1]),
      y: entries.map((elem) => steps[elem[0]]),
      hoverinfo: "x+percent previous+percent initial",
      textposition: "outside",
      textinfo: "value+percent initial",
      marker: {
        color: [
          "#D99CA5",
          "#EDCB9C",
          "#EFEFA8",
          "#C2E0AE",
          "#C0ECD8",
          "#8Fc2DF",
          "#A19AD1",
        ],
      },
    },
  ];
};
